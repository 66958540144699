import { Box, BoxProps, Container, Stack } from '@chakra-ui/react';
import Image from 'next/image';
import { BreadcrumbHeader } from '../../Elements/BreadcrumbHeader/BreadcrumbHeader';
import { ContentRenderer } from '../../Elements/ContentRenderer/ContentRenderer';
import { PrintAndShare } from '../../SmartComponents/PrintAndShareSection/PrintAndShare';

export interface BlogDetailProps extends BoxProps {
  title: string;
  subtitle: string;
  body: string;
  image: string;
}

export const BlogDetail = (props: BlogDetailProps) => {
  const { title, subtitle, image, body, ...restOfProps } = props;
  return (
    <Box {...restOfProps} w="full">
      <Stack spacing={{ base: 4, md: 6 }} alignItems={'center'}>
        <BreadcrumbHeader
          title={title}
          subtitle={subtitle}
          maxW={'container.lg'}
        />
        <Container maxW={'container.lg'}>
          <Stack spacing={{ base: 4, md: 8 }}>
            <Box
              position="relative"
              w="full"
              h={{ base: 60, md: 480 }}
              boxShadow="xl"
            >
              <Image
                src={image}
                layout="fill"
                priority
                quality={48}
                objectFit="cover"
                unoptimized
              />
            </Box>
            {/* <SectionHeading title={title} w="full" /> */}
            <ContentRenderer fontSize={'md'}>{body}</ContentRenderer>
            <PrintAndShare />
          </Stack>
        </Container>
      </Stack>
    </Box>
  );
};
