import { Box, BoxProps, Flex } from '@chakra-ui/react';
import Image from 'next/image';
import { ContentRenderer } from '../../Elements/ContentRenderer/ContentRenderer';
import { Container } from '../../Layout/Container/Container';

export interface ImageContentSectionProps extends BoxProps {
  image: string;
  content: string;
}

export const ImageContentSection = (props: ImageContentSectionProps) => {
  const { image, content, ...restOfProps } = props;
  return (
    <Box {...restOfProps}>
      <Container>
        <Flex
          gridGap={{ base: 6, md: 6, lg: 8 }}
          flexDir={{ base: 'column', md: 'row' }}
          mx={{ base: -4, md: 0 }}
        >
          <Box
            bg="grey.500"
            position="relative"
            h={{ base: 56, sm: 72, md: 'auto' }}
            w={{ base: 'full', md: '50%', lg: '40%' }}
          >
            <Image src={image} layout="fill" objectFit="cover" unoptimized />
          </Box>
          <Box flex={1} mx={{ base: 4, md: 0 }} mt={-6}>
            <ContentRenderer fontSize={'sm'} useTickForUl>
              {content}
            </ContentRenderer>
          </Box>
        </Flex>
      </Container>
    </Box>
  );
};
