import { Box, BoxProps, Flex, Stack, Text } from '@chakra-ui/react';
import Image from 'next/image';
import { Container } from '../../Layout/Container/Container';
import { Underline } from '../Underline/Underline';

export interface ImageFormHeaderProps extends BoxProps {
  title: string;
  description?: string;
  image: string;
}

export const ImageFormHeader = (props: ImageFormHeaderProps) => {
  const { title, description, image, ...restOfProps } = props;
  return (
    <Box w="full" {...restOfProps}>
      <Flex
        justifyContent="center"
        minH={{
          base: 120,
          sm: 110,
          md: 100,
          lg: 100,
          xl: 100,
        }}
        position="relative"
      >
        {/* BG IMAGE */}
        <Box position="absolute" h="full" w="full">
          <Box position="relative" h="full" w="full">
            <Image
              src={image}
              layout="fill"
              alt="form-header"
              quality={100}
              objectFit="cover"
              unoptimized
            />
          </Box>
        </Box>
        {/* CONTENT */}
        <Container position="absolute">
          <Stack
            w="full"
            h="full"
            justifyContent={'center'}
            color="white"
            py={6}
            spacing={0}
          >
            <Text
              as="h3"
              fontWeight={600}
              textTransform={'uppercase'}
              fontSize={{ base: 'xl', sm: '2xl', md: '2xl', lg: '3xl' }}
            >
              {title}
            </Text>
            <Text
              as="p"
              fontSize={{ base: 'xs', sm: 'sm', md: 'sm', lg: 'sm' }}
            >
              {description}
            </Text>
          </Stack>
        </Container>
      </Flex>
      <Underline h={1.5} />
    </Box>
  );
};
