import { Box, BoxProps } from '@chakra-ui/react';
import React from 'react';
import Slider, { Settings } from 'react-slick';
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { ChevronSliderArrow } from '../ChevronSlider/components/ChevronSliderArrow';
import { ImageSliderDots } from './ImageSliderDots';
import { ImageSliderItem } from './ImageSliderItem';

const settings: Settings = {
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: false,
  speed: 700,
  autoplaySpeed: 8000,
  infinite: false,
  // arrows are handled completely custom
  arrows: false,
  dots: true,
};

export interface ImageSliderProps extends BoxProps {
  images: string[];
  priority?: boolean;
  showOverlay?: boolean;
}

export const ImageSlider = (props: ImageSliderProps) => {
  const {
    images,
    priority = false,
    showOverlay = false,
    ...restOfProps
  } = props;
  const sliderRef = React.createRef<Slider>();
  const showArrows = images.length > 1;

  settings.appendDots = (dots) => (
    <ImageSliderDots
      dots={dots}
      setSlide={(i: number) => {
        sliderRef.current?.slickGoTo(i);
      }}
    />
  );

  return (
    <SimpleReactLightbox>
      <SRLWrapper
        key={'image slider'}
        callbacks={{
          onLightboxClosed: (object) => {
            sliderRef.current?.slickGoTo(0);
          },
        }}
      >
        <Box position="relative" {...restOfProps}>
          {showArrows && (
            <ChevronSliderArrow
              position="absolute"
              top={'50%'}
              transform={'translateY(-50%)'}
              left={{ base: 0, sm: 4, md: 12 }}
              color={'white'}
              _hover={{ color: '#ffffffcc' }}
              type="prev"
              onClick={() => sliderRef?.current?.slickPrev()}
            />
          )}

          <Slider {...settings} ref={sliderRef}>
            {images.map((image, i) => (
              <Box key={i}>
                <ImageSliderItem
                  imageProps={{
                    src: image,
                    quality: i === 0 ? 80 : 60,
                    unoptimized: true,
                  }}
                  priority={priority ? i === 0 : false}
                />
              </Box>
            ))}
          </Slider>
          {showArrows && (
            <ChevronSliderArrow
              position="absolute"
              top={'50%'}
              transform={'translateY(-50%)'}
              right={{ base: 0, sm: 4, md: 12 }}
              color={'white'}
              _hover={{ color: '#ffffffcc' }}
              type="next"
              onClick={() => sliderRef?.current?.slickNext()}
            />
          )}
        </Box>
      </SRLWrapper>
    </SimpleReactLightbox>
  );
};
